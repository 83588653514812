
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import Container from "typedi";
import LoginInteractor from "@interactors/login/Login.interactor";

export default defineComponent({
    name: "cpanel",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const signInValidation = Yup.object().shape({
            siteId: Yup.number().required().label("Site ID"),
        });

        const onSubmitSignIn = async function(values) {
            if (submitButton.value) {
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            // Call login interactor
            try {
                await Container.get(LoginInteractor).loginBySiteId(values.siteId);
            } catch (error) {
                console.log(error);
                submitButton.value?.removeAttribute("data-kt-indicator");
            }
        };

        return {
            messagesError: computed(() => store.getters.getErrors),
            submitButton,
            signInValidation,
            onSubmitSignIn,
        };
    }
});
